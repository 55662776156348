import {BaseModelInterface} from "@/models/BaseModelInterface";
import {UnwrapNestedRefs} from "@vue/reactivity";
import {AxiosError, AxiosResponse} from "axios";
import {onBeforeUnmount, onMounted, reactive} from "vue";
import {showError} from "@/services/Utils";
import {Store, useStore} from "vuex";
import {BaseModel} from "@/models/BaseModel";

/**
 * Interface for parameters that are passed to endpoints to fetch data
 * it includes
 * filter data,
 * pagination data: sort, perPage, page
 */
export interface ActionParamsInterface {
    /**
     * Sort param defined in Yii2 ActiveDataProvider
     * @see https://www.yiiframework.com/doc/guide/2.0/en/output-sorting
     */
    sort?: string | null;

    /**
     * The current page number for Yii2 ActiveDataProvider
     * @see https://www.yiiframework.com/doc/guide/2.0/en/output-pagination
     */
    page?: number | null;

    /**
     * Filters for Yii2 Queries
     */
    filter?: { [key: string]: any };

    /**
     * If the action should be executed regardless of the cache
     */
    force?: boolean;

    /**
     * In case of single Actions -> the ID of the data
     */
    id?: number | null;

    /**
     * In case of single Actions by slug -> the slug of the data
     */
    slug2?: string | null;

    /**
     * How many rows should be fetched -> this is the pageSize in Yii2 ActiveDataProviders Pagination
     * @see https://www.yiiframework.com/doc/guide/2.0/en/output-pagination
     */
    'per-page'?: number | null;

    [key: string]: any
}

export type ActionParamsCallback = () => ActionParamsInterface | number | null

/**
 * Interface for an Action
 */
export interface ActionInterface {
    /**
     * The action that should be triggered, this can be a vuex store action or a function
     *
     * @type {null|string|Function}
     */
    type: null | string | Function;

    /**
     * The params that should be passed to the vuex store mutation or the function in "type"
     * can be a function or an object
     *
     * @type {null|Object|Function}
     */
    params?: boolean | null | ActionParamsInterface | ActionParamsCallback;

    /**
     * If the loading state should not be touched when this loading is called
     *
     * @type {boolean}
     */
    hideLoading?: boolean;

    /**
     * A condition if the action should even be executed
     *
     * @type {null}
     */
    if?: null | Function;

    /**
     * An Attribute name that should store the pagination
     *
     * @type {null|String}
     */
    pagination?: null | string;

    /**
     * An Attribute name that should contain the fetched data
     *
     * @type {null|string}
     */
    data?: null | string;

    /**
     * A success callback in case everything was correct
     *
     * @type {null|Function}
     */
    success?: null | Function;

    /**
     * If it's an axios promise and not a custom already parsed store promise
     *
     * @type {boolean}
     */
    isAxiosPromise?: boolean;

    /**
     * A modelClass
     *
     * @type {null|BaseModel|Function}
     */
    modelClass?: new() => BaseModel;
    /**
     * If this data should only be fetched one single time
     *
     * @type {boolean}
     */
    once?: boolean;
    /**
     * If the action was already performed
     *
     * @type {boolean}
     * @see Action.once
     */
    done?: boolean;

    /**
     * Store cache usage
     *
     * @type {null|{getter: string, id: number}}
     */
    cache?: null | { getter: string, id: number | null | Function | string };

    /**
     * Callback that is invoked after the action
     *
     * @type {null|Function}
     */
    afterAction?: null | Function;

    /**
     * optional catch function for error handler
     */
    catch?: Function | null;

    /**
     * Should errors be displayed or not?
     */
    displayError?: boolean | Function;
}

export class Action implements ActionInterface {
    /**
     * The action that should be triggered, this can be a vuex store action or a function
     *
     * @type {null|string|Function}
     */
    type: null | string | Function = null;

    /**
     * The params that should be passed to the vuex store mutation or the function in "type"
     * can be a function or an object
     *
     * @type {null|Object|Function}
     */
    params: boolean | null | { [key: string]: any } | ActionParamsCallback = null;

    /**
     * If the loading state should not be touched when this loading is called
     *
     * @type {boolean}
     */
    hideLoading: boolean = false;

    /**
     * A condition if the action should even be executed
     *
     * @type {null}
     */
    if: null | Function = null;

    /**
     * An Attribute name that should store the pagination
     *
     * @type {null|String}
     */
    pagination: string | null = null;

    /**
     * An Attribute name that should contain the fetched data
     *
     * @type {null|string}
     */
    data: string | null = null;

    /**
     * A success callback in case everything was correct
     *
     * @type {null|Function}
     */
    success: null | Function = null;

    /**
     * If it's an axios promise and not a custom already parsed store promise
     *
     * @type {boolean}
     */
    isAxiosPromise: boolean = false;

    /**
     * A modelClass
     *
     * @type {null|BaseModel|Function}
     */
    modelClass: any | Function = null;
    /**
     * If this data should only be fetched one single time
     *
     * @type {boolean}
     */
    once: boolean = false;
    /**
     * If the action was already performed
     *
     * @type {boolean}
     * @see Action.once
     */
    done: boolean = false;

    /**
     * Store cache usage
     *
     * @type {null|{getter: string, id: string | number | null}}
     */
    cache: null | { getter: string, id: number | null | Function | string } = null;

    /**
     * Callback that is invoked after the action
     *
     * @type {null|Function}
     */
    afterAction: null | Function = null;

    /**
     * error handler, that is executed, when an exception is cached
     */
    catch: Function | null = null;

    /**
     * If errors should be displayed or not
     */
    displayError: boolean | Function = true;

    /**
     * Constructor of an action, this class represents a single call to an endpoint
     * multiple actions can be executed during one call
     * there are several parameters do define if an action should be executed [[if]] and [[once]]
     *
     * you can specify the endpoint/target with the required [[type]] parameter and define params that are passed
     * to the endpoints via [[params]] parameter
     *
     * @param {string|function}                     params.type                 the vuex action or a callback that will
     *                                                                          return an axios promise
     * @param {object|function}                     [params.params]             params, that are passed to the function
     *                                                                          or the vuex action
     * @param {null|function}                       [params.afterAction]        callback that is invoked after the single
     *                                                                          action is resolved
     * @param {boolean|function}                    [params.hideLoading]        do not change the loading state for
     *                                                                          this action
     * @param {boolean|function}                    [params.if]                 callback or a boolean value if the
     *                                                                          action should be executed
     * @param {string|null}                         [params.pagination]         string parameter where the pagination
     *                                                                          information should be stores
     *                                                                          in the dataState
     * @param {string|null|Null}                    [params.data]               string parameter where the response data
     *                                                                          should be stored in the dataState
     * @param {boolean|null}                        [params.isAxiosPromise]     bool variable if the request is an axios
     *                                                                          Promise or a normal promise
     * @param {null|BaseModel|Function}             [params.modelClass]         in case of an axios promise, you can
     *                                                                          define a modelClass, the response data
     *                                                                          will then contain objects of these models
     * @param {boolean}                             [params.once]               if the action should only be done once
     *                                                                          and not every time -> used to fetch
     *                                                                          related data once and then other actions
     *                                                                          during every execution
     * @param {{getter: string, id: Number}|null}   [params.cache]              only for vuex -> try to get the data
     *                                                                          via store first
     */
    constructor(params: ActionInterface) {
        this.type = params?.type ?? null;
        this.params = params?.params ?? null;
        this.if = params?.if ?? null;
        this.pagination = params?.pagination ?? null;
        this.data = params?.data ?? null;
        this.modelClass = params?.modelClass ?? null;
        this.once = params?.once ?? false;
        this.isAxiosPromise = params?.isAxiosPromise ?? false;
        this.hideLoading = params?.hideLoading ?? false;
        this.cache = params?.cache ?? null;
        this.afterAction = params?.afterAction ?? null;
        this.catch = params?.catch ?? null;
        this.displayError = params?.displayError ?? true;
    }
}

interface HashMutationConstruct {
    data?: string | null;
    mutation?: string | null;
    compare?: null | { (oldValue: string, newValue: string, state: DataStateInterface): boolean };
    setPayload?: null | { (state: DataStateInterface, key: string, value: any): void };
}

/**
 * HashMutation class
 */
export class HashMutation implements HashMutationConstruct {
    /**
     * The property name where the local hash is stored in the dataState object
     */
    data: null | string = null;

    /**
     * The mutation string in the Vuex store, mostly this will be 'entity/SET_HASH'
     */
    mutation: null | string = null;

    /**
     * A compare function that is called
     *
     * @type {null|Function}
     */
    compare: null | { (oldValue: string, newValue: string, state: DataStateInterface): boolean } = null;

    /**
     * A function to set the payload of the local hash, eg format a DateTime/moment object
     *
     * @type {null|Function}
     */
    setPayload: null | { (state: DataStateInterface, key: string, value: any): void } = null;

    constructor(options: HashMutationConstruct) {
        this.data = options?.data ?? null;
        this.mutation = options?.mutation ?? null;
        this.compare = options?.compare ?? null;
        this.setPayload = options?.setPayload ?? null;
    }
}

/**
 * Interface for the constructor for fetching data
 */
interface FetchDataCompositionConstructor {
    onLoadingStart?: null | Function;
    onLoadingEnd?: null | Function;

    hashMutations?: HashMutation[]

    actions: Action[];
}

interface DataStateInterface {
    loading: boolean;
    currentPage: number;
    ready: boolean;

    [key: string]: any
}

/**
 *
 * @param {{}} options
 * @param {Store|Object} options.store
 * @param {Function} [options.onLoadingStart]
 * @param {Function} [options.onLoadingEnd]
 * @param {{type: String|Function}[]} [options.actions]
 * @return {{fetchData: (function(): Promise), changePage: (function: Promise<unknown>|Promise<unknown[]>), unMounted: Function, dataState: UnwrapNestedRefs<{ready: boolean, loading: boolean, currentPage: number}>, mounted: false | Function}}
 */
export default function (options: FetchDataCompositionConstructor) {
    let dataObject: DataStateInterface = {
        loading: false,
        currentPage: 1,
        ready: false
    };
    const store: Store<any> = useStore();

    const loadingStartFunction = options?.onLoadingStart ?? null;
    const loadingEndFunction = options?.onLoadingEnd ?? null;

    /**
     * Function when loading starts
     */
    const startLoading = () => {
        dataState.loading = true;
        if (loadingStartFunction && typeof loadingStartFunction === 'function') {
            loadingStartFunction.call(null);
        }
    }

    /**
     * Function when loading ends
     */
    const endLoading = () => {
        dataState.loading = false;
        if (loadingEndFunction && typeof loadingEndFunction === 'function') {
            loadingEndFunction.call(null);
        }
    }

    /**
     * Select all properties that should be cleared
     * when the client is offline or the store is changed
     * @type {String[]}
     */
    let propertiesToClear: string[] = [];
    /**
     * Reset all properties to the basic state
     */
    const clearStorage = () => {
        propertiesToClear.forEach(property => {
            dataState[property] = []
        })
    }

    let passedHasMutations = options?.hashMutations ?? [];
    let hashMutations: HashMutation[] = [];
    if (Array.isArray(passedHasMutations)) {
        passedHasMutations.forEach((el: any) => {
            if (!(el instanceof HashMutation)) {
                el = new HashMutation(el);
            }
            hashMutations.push(el);
        });
    } else {
        throw new Error('HashMutation must be an array')
    }


    // start looping the actions if there are any
    let actions = options.actions ?? [];
    let actionObjects: Action[] = [];
    actions.forEach(action => {
        // make sure it's a class
        const actionObject = action;

        /**
         * Add the pagination param to the reactive state
         */
        if (actionObject.pagination !== null) {
            dataObject[actionObject.pagination] = null;
            propertiesToClear.push(actionObject.pagination);
        }

        /**
         * Add a data param to the reactive state
         */
        if (actionObject.data !== null) {
            dataObject[actionObject.data] = [];
            propertiesToClear.push(actionObject.data);
        }

        actionObjects.push(actionObject);
    });

    const dataState: UnwrapNestedRefs<DataStateInterface> = reactive(dataObject);

    let unSubscribeAction: Function | null = null;
    let unSubscribeMutation: Function | null = null;
    let unSubscribeActionHash: any = {};
    const mounted = onMounted(() => {
        /**
         *  After the store is cleared or a store is selected change your data
         */
        unSubscribeAction = store.subscribeAction({
            after: (action) => {
                if (action.type === 'refresh') {
                    clearStorage();
                    actionObjects.forEach(action => {
                        action.done = false;
                    })
                    fetchData();
                }

                // fetch data after login
                if (action.type === 'user/login' && store.getters['user/isLoggedIn'] === true) {
                    fetchData()
                }
            }
        });

        /**
         * Refresh the data after you fetch the stores..
         */
        unSubscribeMutation = store.subscribe((action) => {
            if (action.type === 'fetchStores') {
                fetchData();
            }
        });

        // reload data if the hash in the store changes
        hashMutations.forEach((hashMutation: HashMutation) => {
            const key = hashMutation.data;
            if (key !== null) {
                const mutationType = hashMutation.mutation;

                const compareFunction = typeof hashMutation.compare === 'function' ? hashMutation.compare : (oldValue: any, newValue: any, state: DataStateInterface) => {
                    return oldValue !== newValue
                };

                const setPayload = typeof hashMutation.setPayload === 'function' ? hashMutation.setPayload : (state: DataStateInterface, key: string, value: any) => {
                    state[key] = value;
                };

                unSubscribeActionHash[key] = store.subscribe((mutation) => {
                    if (mutation.type === mutationType && compareFunction(dataState[key], mutation.payload, dataState) && dataState.loading === false) {
                        setPayload(dataState, key, mutation.payload);
                        fetchData();
                    }
                });
            }
        });


        window.addEventListener('online', fetchData);
        window.addEventListener('offline', clearStorage);

        init();
    });

    /**
     * Function before unmount
     * remove event listener
     */
    const unMounted = onBeforeUnmount(() => {
        window.removeEventListener('online', fetchData);
        window.removeEventListener('offline', clearStorage);

        if (unSubscribeAction) {
            unSubscribeAction();
        }
        if (unSubscribeMutation) {
            unSubscribeMutation();
        }

        for (let i in unSubscribeActionHash) {
            if (Object.prototype.hasOwnProperty.call(unSubscribeActionHash, i) === true && typeof unSubscribeActionHash[i] === 'function') {
                unSubscribeActionHash[i]()
            }
        }
    })

    // methods
    // ===================================================

    // const fetchDataFunction = options?.fetchData ?? null;
    const fetchData = (): Promise<(BaseModelInterface | BaseModelInterface[])[]> => {
        // if (typeof fetchDataFunction === 'function') {
        //     return fetchDataFunction();
        // }

        if (dataState.loading === true) {
            return new Promise((resolve, reject) => {
                reject('already loading');
            })
        }

        /**
         * All executed actions
         *
         * @type {Action[]}
         */
        let executedActions: Action[] = [];
        actionObjects.forEach(action => {

            const firstCondition = (action.once && !action.done) || !action.once;
            if (action.cache === null && firstCondition && (action.if === null || action.if?.call(null) === true)) {
                executedActions.push(action);
            }

            if (action.cache) {
                const {getter, id} = action.cache;
                const result = store.getters[getter](id);
                if (result === null) {
                    executedActions.push(action);
                } else {
                    if(typeof action.afterAction === 'function'){
                        action.afterAction(result);
                    }

                    if (action.data){
                        dataState[action.data] = result;
                    }
                }
            }
        });

        if (executedActions.length >= 1) {
            startLoading();
        }

        let done = 0;
        let promises: any = [];
        executedActions.forEach(action => {
            let searchOptions: ActionParamsInterface | null | { [key: string]: any } | boolean = action.params;
            if (typeof searchOptions === 'function') {
                searchOptions = searchOptions.call(null);
            }

            if (searchOptions === false) {
                return;
            }

            let promise = null;
            if (typeof action.type === 'string') {
                promise = store.dispatch(action.type, searchOptions);
            } else if (typeof action.type === 'function') {
                promise = action.type(searchOptions);
            }

            if (promise !== null && promise instanceof Promise) {
                promise
                    .then((response: AxiosResponse<{ [key: string]: any }[]> | BaseModel[] | BaseModel) => {
                        action.done = true;
                        if (action.data !== null) {
                            let models: BaseModel[] | { [key: string]: any }[] = [];
                            if (action.isAxiosPromise && typeof (response as AxiosResponse).data !== 'undefined') {
                                models = [];
                                const modelClass = action.modelClass;
                                (response as AxiosResponse).data.forEach((el: any) => {
                                    if (modelClass && !(el instanceof modelClass)) {
                                        el = new modelClass(el);
                                    }
                                    models.push(el);
                                })
                            } else {
                                models = <BaseModel[]>response;
                            }

                            if (!Array.isArray(models) && action.cache === null) {
                                dataState[action.data] = (models as BaseModel).clone();
                            } else {
                                dataState[action.data] = models;
                            }

                        }

                        if (action.pagination && Array.isArray(response)) {
                            dataState[action.pagination] = response.pagination;
                        }

                        if (typeof action.afterAction === 'function') {
                            action.afterAction(response);
                        }
                    })
                    .catch((err: AxiosError) => {
                        // check if the error should be displayed globally...
                        if (action.displayError === true || (typeof action.displayError === 'function' && action.displayError() === true)) {
                            showError(err);
                        }

                        if (action.catch !== null) {
                            action.catch(err);
                        }

                        // this.displayError(err, this);
                    })
                    .finally(() => {
                        done++;
                        // hide the loading state after all are resolved
                        if (done === executedActions.length) {
                            endLoading();
                        }
                    });

                promises.push(promise);
            }
        });

        if (promises.length === 0) {
            endLoading();
        }

        return Promise.all(promises);
    };

    /**
     * Fetch a Page
     *
     * @param page
     * @return {Promise<BaseModel> | Promise<BaseModel[]>}
     */
    const changePage = (page: number): Promise<(BaseModelInterface | BaseModelInterface[])[]> => {
        dataState.currentPage = page;
        return fetchData();
    };

    const init = () => {
        const promise = fetchData();
        if (promise) {
            promise
                .then(() => {
                    dataState.ready = true;
                })
                .catch(() => {
                });
        }
    }

    return {
        mounted,
        unMounted,
        dataState,
        fetchData,
        changePage
    }
}
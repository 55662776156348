<template>
    <div />
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "ProgressSpinner",
});
</script>

<style>
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}
</style>
/**
 * Loader composition for Vue
 *
 * @param context
 * @return {{isLoading: boolean, endLoading: function, startLoading: (function(): *)}}
 */
import {ComponentInternalInstance} from "@vue/runtime-core";
import {render, ref, createVNode, ComponentPublicInstance} from "vue";
// @ts-ignore
import Loader from "@/components/base/Loader";
import {Ref} from "@vue/reactivity";

export type ContainerNode = Node & ComponentPublicInstance | Node;

export interface LoadingInterface {
    /**
     * Check if the component is loading
     */
    isLoading: Ref<boolean>;

    /**
     * Function to finish loading within a container
     * @param container
     */
    startLoading(container?: ContainerNode | null): ComponentInternalInstance | null;

    /**
     * Function to start loading
     */
    endLoading(): void;
}

/**
 * The default loading spinner
 *
 * @param {Object|null} loadingContainer
 * @return {{isLoading: ToRef<boolean> | Ref<UnwrapRef<boolean>> | Ref<any | undefined>, endLoading: endLoading, startLoading: (function(): Component)}}
 */
export default function (loadingContainer: Ref<ContainerNode | null> | null = null): LoadingInterface {
    let isLoading = ref(false);

    let subContainer = null;
    /**
     * Get the loading container where it should be appended to
     *
     * @return {HTMLElement|null}
     */
    const getContainer = (container: ContainerNode | null = null): ContainerNode | Node | null => {
        if (container !== null) {
            return container;
        }

        return loadingContainer?.value ?? <Node>document.getElementById('app');
    }

    let loadingInstance: any = null;

    /**
     * Start the loading
     *
     * @return {ComponentInternalInstance}
     */
    const startLoading = (container: ContainerNode | null = null): ComponentInternalInstance | null => {
        isLoading.value = true;
        if (loadingInstance !== null) {
            if (typeof loadingInstance.ctx === 'object' && loadingInstance.ctx.show === 'function') {
                loadingInstance.ctx.show();
            }
            return loadingInstance;
        }

        const loadingContainer: ContainerNode | Node | null = getContainer(container);
        if (loadingContainer === null) {
            throw new Error('Could not find loading container');
        }

        subContainer = document.createDocumentFragment();
        const propsData = {
            programmatic: true,
            container: subContainer,
        };

        let vNode = createVNode(Loader, propsData);
        // @ts-ignore
        render(vNode, subContainer);
        // @ts-ignore
        const $el: Node | null = loadingContainer.$el ?? null;
        if (typeof loadingContainer.appendChild === 'function') {
            loadingContainer.appendChild(subContainer);
        } else if ($el) {
            $el.appendChild(subContainer);
        }
        loadingInstance = vNode.component;

        return loadingInstance;
    }

    /**
     * Finish loading
     */
    const endLoading = () => {
        isLoading.value = false;
        if (loadingInstance) {
            // console.log('loadinginstance', loadingInstance);
            // loadingInstance.ctx.hide();
            render(null, loadingInstance.props.container);
            loadingInstance = null;
        }
    }

    return {
        startLoading,
        endLoading,
        isLoading
    } as LoadingInterface
}

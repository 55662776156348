<template>
    <transition
        ref="root"
        :name="transition">
        <div
            v-show="isActive"
            tabindex="0"
            class="overlay"
            :aria-busy="isActive"
            aria-label="Loading"
            :style="{ zIndex }">
            <div class="dot-falling" />
        </div>
    </transition>
</template>

<script>
import {
    defineComponent,
    onMounted,
    ref,
    watch,
    onUnmounted,
    computed,
} from "vue";
import ProgressSpinner from "@/components/base/ProgressSpinner.vue";


export default defineComponent({
    name: "VueLoading",
    components: {
        ProgressSpinner,
    },
    props: {
        active: {
            type: Boolean,
            default: true
        },
        programmatic: {
            type: Boolean,
            default: true,
        },
        container: {
            type: [Object, Function, HTMLElement],
            required: true
        },
        isFullPage: {
            type: Boolean,
            default: true,
        },
        enforceFocus: {
            type: Boolean,
            default: true,
        },
        lockScroll: {
            type: Boolean,
            default: false,
        },
        transition: {
            type: String,
            default: "fade",
        },
        /**
         * Allow user to hide the loader
         */
        canCancel: {
            type: Boolean,
            default: true
        },
        /**
         * Do something on cancel
         */
        onCancel: {
            type: Function,
            default: () => {
                return;
            },
        },
        color: {
            type: String,
            default: ''
        },
        backgroundColor: {
            type: String,
            default: null,
        },
        blur: {
            type: String,
            default: "2px",
        },
        opacity: {
            type: Number,
            default: 1,
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 100,
        },
        zIndex: {
            type: Number,
            default: 100000000,
        },
        loader: {
            type: [String, Object],
            default: "ProgressSpinner",
        },
    },
    setup(props) {
        const isActive = ref(props.active);
        const bgStyle = computed(() => {
            let ret = {
                background: props.backgroundColor,
                opacity: props.opacity,
                backdropFilter: `blur(${props.blur})`,
            };
            return ret;
        });

        const show = () => {
            isActive.value = true;
        };

        const hide = () => {
            // Timeout for the animation complete before destroying
            if (props.programmatic) {
                isActive.value = false;
            }
        };
        const cancel = () => {
            if (!props.canCancel || !isActive.value) return;
            hide();
            props.onCancel();
        };
        const keyUp = (event) => {
            if (event.keyCode === 27) {
                cancel();
            }
        };
        const disableScroll = () => {
            if (props.isFullPage && props.lockScroll) {
                document.body.classList.add("vld-shown");
            }
        };
        const enableScroll = () => {
            if (props.isFullPage && props.lockScroll) {
                document.body.classList.remove("vld-shown");
            }
        };
        onMounted(() => {
            if (props.programmatic) {
                isActive.value = true;
                document.addEventListener("keyup", keyUp);
            }
        });
        onUnmounted(() => {
            document.removeEventListener("keyup", keyUp);
        });
        watch(
            () => props.active,
            () => {
                isActive.value = props.active;
            }
        );
        watch(isActive, () => {
            if (isActive.value) {
                disableScroll();
            } else {
                enableScroll();
            }
        });
        return {
            isActive,
            bgStyle,
            hide,
            cancel,
            show
        };
    }
});
</script>

<style scoped>

</style>
